import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Transition } from 'react-transition-group';

// Utils
import { disableBodyScroll, clearAllBodyScrollLocks } from '../../utils/body-scroll-lock';

// Styles
import styles from './Modal.styl';

const cx = classNames.bind(styles);

const modalRoot = typeof window !== 'undefined' ? document.getElementById('modal-root') : null;

export default function Modal(props) {
  const { className, children, isOpen, onClose, rootClassName } = props;

  const overlayRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(overlayRef.current);
    }

    return () => {
      if (isOpen) {
        clearAllBodyScrollLocks();
      }
    };
  }, [isOpen]);

  return (
    modalRoot &&
    createPortal(
      <Transition in={isOpen} timeout={{ enter: 0, exit: 700 }} mountOnEnter unmountOnExit>
        {state => (
          <>
            <div
              className={cx('ModalPhone__overlay', `ModalPhone__overlay_${state}`)}
              onClick={onClose}
              role="button"
              tabIndex={0}
              onKeyDown={() => null}
            />
            <div
              className={cx('ModalPhone', `ModalPhone_${state}`, rootClassName)}
              ref={overlayRef}
              id="modal"
            >
              <div className={cx('ModalPhone__content', className)}>
                {children}
                <div className={cx('ModalPhone__padding')} />
              </div>
            </div>
          </>
        )}
      </Transition>,
      modalRoot,
    )
  );
}

Modal.defaultProps = {
  className: '',
  isOpen: false,
  onClose: () => null,
  children: null,
};

Modal.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
};
