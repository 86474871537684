import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './styles.styl';

import img from '../assets/link.png';
import { getRoute, paths } from '../../../entry/routes';
import ModalComponentCheckpoint from './ModalComponentCheckpoint';

const cx = classNames.bind(styles);

const HalloweenLink = ({ className, ...props }) => {
  return (
    <>
      <Link
        to={getRoute(paths.halloween)}
        className={cx(styles.HalloweenLink, className)}
        {...props}
      >
        <img className={cx(styles.HalloweenLink_img)} src={img} alt="decor" />
      </Link>
      <ModalComponentCheckpoint />
    </>
  );
};

export default HalloweenLink;
