import React from 'react';

import classNames from 'classnames/bind';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const BlurredBordered = ({ className, ...props }) => {
  return <div className={cx(styles.BlurredBordered, className)} {...props} />;
};

export default BlurredBordered;
