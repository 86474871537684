import React, {useMemo, useRef} from 'react';
import classNames from 'classnames/bind';
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";

//Utils
import {setGlobalMessage} from "../../../../utils/setGlobalMessage";

// Actions
import {closeGamePopup} from "../../../../state/modules/promotionGame/actions";

//Icons
import CrossIcon from '../../../../icons/cross-grey.svg';
import SmileIcon from '../../../../icons/smile.svg';
import SmileOppositeIcon from '../../../../icons/smile_oposite.svg';
import CopyIcon from '../../../../icons/copy.svg';

// Assets
import flagsDesktop from '../../../../assets/FoolDay_images/popup_flags_desktop.png';
import flags from '../../../../assets/FoolDay_images/popup_flags.png';
// MM
import mm_default from '../assets/mm_game/popup_decor.png';
import mm_falsy from '../assets/mm_game/popup_err.png';
import mm_repeat from '../assets/mm_game/popup_repeat.png';
import art1 from '../assets/mm_game/checkpoint_1.png';
import art2 from '../assets/mm_game/checkpoint_2.png';
import art3 from '../assets/mm_game/checkpoint_3.png';
import art4 from '../assets/mm_game/checkpoint_4.png';
import art5 from '../assets/mm_game/checkpoint_5.png';


//Styles
import styles from './PopupPromoGame.styl';
import {themeNameSpaces} from "../../../../state/modules/city/themeNameSpaces";
import HalloweenLink from '../../../Halloween/components/HalloweenLink';

const cx = classNames.bind(styles);

const promoGameRulesPages = {
  [themeNameSpaces.HALLOWEEN_QUEST]: '/stock/sousy-ili-zizn',
  [themeNameSpaces.FOOLS_DAY]: '/stock/focus-pocus-pizza-za-ruble',
  [themeNameSpaces.MASTER_MARGO]: '/stock/razgadaj-zagadki-mastera-i-margarity-',
  [themeNameSpaces.HALLOWEEN_24]: '/halloween',
}

const masterMargoImages = {
  falsyArtefact: mm_falsy,
  repeated: mm_repeat,
  default: mm_default
}

const masterMargoArtefacts = [art1, art2, art3, art4, art5]

const PopupPromoGame = ({text, closeModal, className}) => {

  const {isRealPhone, isRealTablet} = useSelector(state => state.responsive);
  const {_supertag} = useSelector(state => state.city.userCity);
  const promoGameProperties = useSelector(state => state.promotionGame);

  const dispatch = useDispatch();
  const history = useHistory()
  const intl = useIntl();

  const {
    gameName,
    artefacts,
    common: {
      artefactsTotalCount,
      artefactsCheckedCount,
      isPopupShown,
      isArtefactPreviouslyChecked,
      isPromoProgressFinished,
      rewardCode,
      falsyArtefactMessageShow
    }

  } = promoGameProperties

  const closeHandler = (e) => {
    e.stopPropagation()
    dispatch(closeGamePopup())
  }

  const redirect = () => {
    history.push(`${_supertag}${promoGameRulesPages[gameName]}`)
  }

  const onCopyHandler = async (e) => {
    e.stopPropagation()
    await navigator.clipboard.writeText(rewardCode)
    setGlobalMessage(intl.formatMessage({id: 'promoGame.popup.copy'}), {type: 'success'})
  }

  const translates = useMemo(() => ({
    [themeNameSpaces.HALLOWEEN_QUEST]: {
      falsyArtefact: intl.formatMessage({id: 'promoGame.popup.checkpoint.falsyArtefact.halloween_quest'}),
      repeated: intl.formatMessage({id: 'promoGame.popup.checkpoint.repeated.halloween_quest'}),
      checkpoint: intl.formatMessage({id: 'promoGame.popup.checkpoint.halloween_quest'}, {count: artefactsCheckedCount}),
      success: intl.formatMessage({id: 'promoGame.popup.success.halloween_quest'})
    },
    [themeNameSpaces.FOOLS_DAY]: {
      falsyArtefact: intl.formatMessage({id: 'promoGame.popup.checkpoint.falsyArtefact.foolsDay'}),
      repeated: intl.formatMessage({id: 'promoGame.popup.checkpoint.repeated.foolsDay'}),
      checkpoint: intl.formatMessage({id: 'promoGame.popup.checkpoint.foolsDay'}, {count: artefactsCheckedCount}),
      success: intl.formatMessage({id: 'promoGame.popup.success.foolsDay'})
    },
    [themeNameSpaces.MASTER_MARGO]: {
      falsyArtefact: intl.formatMessage({id: 'promoGame.popup.checkpoint.falsyArtefact.master'}),
      repeated: intl.formatMessage({id: 'promoGame.popup.checkpoint.repeated.master'}),
      checkpoint: intl.formatMessage({id: 'promoGame.popup.checkpoint.master'}, {count: artefactsCheckedCount}),
      success: intl.formatMessage({id: 'promoGame.popup.success.master'})
    },
    [themeNameSpaces.HALLOWEEN_24]: {
      falsyArtefact: intl.formatMessage({id: 'promoGame.popup.checkpoint.falsyArtefact.master'}),
      repeated: intl.formatMessage({id: 'promoGame.popup.checkpoint.repeated.master'}),
      checkpoint: intl.formatMessage({id: 'promoGame.popup.checkpoint.master'}, {count: artefactsCheckedCount}),
      success: intl.formatMessage({id: 'promoGame.popup.success.master'})
    },
  }), [themeNameSpaces, intl, artefactsCheckedCount])

  const isHalloweenGame = useRef(gameName === themeNameSpaces.HALLOWEEN_QUEST).current
  const isMMGame = useRef(gameName === themeNameSpaces.MASTER_MARGO).current
  const isFoolsDay = useRef(gameName === themeNameSpaces.FOOLS_DAY).current

  const trulyArtefact = useMemo(() => {
    if (!isMMGame) return null
    return artefacts.filter(art => !art.isFalsyArtefact).map(art => art.checked)
  }, [artefacts, isMMGame])


  const mmPopupImageSrc = useMemo(() => {
    if (!isMMGame) {
      return null
    }
    if (isPromoProgressFinished){
      return masterMargoImages.default
    }

    if (falsyArtefactMessageShow) {
      return masterMargoImages.falsyArtefact
    }
    if (isArtefactPreviouslyChecked) {
      return masterMargoImages.repeated
    }
    return masterMargoImages.default

  }, [falsyArtefactMessageShow, isArtefactPreviouslyChecked, isMMGame, isPromoProgressFinished])

  if (gameName === themeNameSpaces.HALLOWEEN_24){
    return <HalloweenLink className={cx('PopupPromoGame_hw24',className)}/>
  }

  return (
    <div className={cx('PopupPromoGame', className, {
      PopupPromoGame_hidden: !isPopupShown,
      PopupPromoGame_hw: isHalloweenGame,
      PopupPromoGame_mm: isMMGame,

    })} onClick={redirect}>
      <div className={cx('PopupPromoGame__wrapper')}>
        <div className={cx('PopupPromoGame__content')}>
          <div className={cx('PopupPromoGame__container', {
            PopupPromoGame__decor_hw: isHalloweenGame,
            PopupPromoGame__decor_mm: isMMGame,
          })}
          >
            {isFoolsDay && (
              <img src={isRealPhone || isRealTablet ? flags : flagsDesktop}
                   className={cx('PopupPromoGame__img')}
                   alt="flags"/>
            )}
            {isMMGame && (
              <img src={mmPopupImageSrc}
                   className={cx('PopupPromoGame__img', 'PopupPromoGame__img_mm', {PopupPromoGame__img_mm_small: falsyArtefactMessageShow})}
                   alt="flags"/>
            )}
            <div
              className={cx('PopupPromoGame__right', 'PopupPromoGame__container', 'PopupPromoGame__container_column', {
                PopupPromoGame__content_hw: isHalloweenGame,
                PopupPromoGame__content_mm: isMMGame,
              })}>
              <p className={cx('PopupPromoGame__text')}>
                {falsyArtefactMessageShow && translates[gameName].falsyArtefact}
                {!falsyArtefactMessageShow && ((!isPromoProgressFinished && !rewardCode)
                  ? isArtefactPreviouslyChecked
                    ? translates[gameName].repeated
                    : translates[gameName].checkpoint
                  : translates[gameName].success)
                }
                <span className={cx('PopupPromoGame__smile')}>
                  {((isArtefactPreviouslyChecked || falsyArtefactMessageShow) && !isPromoProgressFinished) && <SmileOppositeIcon/>}
                  {((!isArtefactPreviouslyChecked && !falsyArtefactMessageShow) || isPromoProgressFinished) && <SmileIcon/>}
                </span>
              </p>
              {!falsyArtefactMessageShow && ((!isPromoProgressFinished && !rewardCode) ?
                <div className={cx('PopupPromoGame__progress')}>
                  <div className={cx('PopupPromoGame__container', 'PopupPromoGame__container_column')}>
                    <p className={cx('PopupPromoGame__progress-count')}>
                      <span
                        className={cx('PopupPromoGame__progress-count_passed')}>{artefactsCheckedCount}</span> из {artefactsTotalCount}
                    </p>
                    <div className={cx('PopupPromoGame__progress-bar')}>

                      {isHalloweenGame && <div className={cx('PopupPromoGame__progress-bar__hw-decor')}>
                        {Array(artefactsTotalCount)
                          .fill(true)
                          .map((art, index) => <div key={index}
                                                    className={cx('PopupPromoGame__progress-bar__hw-decor_item',
                                                      {
                                                        'PopupPromoGame__progress-bar__hw-decor_item_done':
                                                          index + 1 <= artefactsCheckedCount
                                                      })}/>)
                        }
                      </div>}

                      {isMMGame && <div className={cx('PopupPromoGame__progress-bar__hw-decor')}>
                        {trulyArtefact
                          .map((isChecked, index) => <div key={index}
                                                          style={{backgroundImage: `url(${masterMargoArtefacts[index]}` ?? ''}}
                                                          className={cx('PopupPromoGame__progress-bar__hw-decor_item',
                                                            {
                                                              'PopupPromoGame__progress-bar__hw-decor_item_done': isChecked
                                                            })}/>)
                        }
                      </div>}


                      {!isMMGame && <div className={cx('PopupPromoGame__progress-bar_success')}
                                         style={{width: `${artefactsCheckedCount / artefactsTotalCount * 100}%`}}/>}
                    </div>
                  </div>
                </div>
                : (
                  <div className={cx('PopupPromoGame__success')} onClick={onCopyHandler}>
                    <div
                      className={cx('PopupPromoGame__success-code')}>{rewardCode}</div>
                    <div className={cx('PopupPromoGame__copy-btn')}>
                      <CopyIcon width={16} height={16}/>
                    </div>
                  </div>))}
            </div>
          </div>
          <button className={cx('PopupPromoGame__button')} onClick={closeHandler}>
            <CrossIcon width={16} height={16}/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupPromoGame;
