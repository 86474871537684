import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styles from './styles.styl';
import Modal from '../../../components/Modal';
import Modal2 from '../../../components/Modal2';
import BlurredBordered from './BlurredBordered';
import { useHalloween } from '../useHalloween';
import Button from '../../../components/Button';

const cx = classNames.bind(styles);

const ModalComponentCheckpoint = ({ className, ...props }) => {
  const intl = useIntl();

  const isRealPhone = useSelector(state => state.responsive.isRealPhone);

  const {
    artefactModal: { isModalOpen, closeModal },
    currentTask,
  } = useHalloween();

  const ModalComp = useRef(isRealPhone ? Modal2 : Modal).current;

  const translates = useRef({
    title: intl.formatMessage({ id: 'promo.halloween24.modal.title' }),
    descr1: intl.formatMessage({ id: 'promo.halloween24.modal.descr1' }),
    descr2: intl.formatMessage({ id: 'promo.halloween24.modal.descr2' }),
    button: intl.formatMessage({ id: 'promo.halloween24.modal.button' }),
  }).current;

  return (
    <ModalComp
      className={cx(styles.ModalContent, className)}
      {...props}
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      {currentTask?.img && (
        <img src={currentTask?.img} alt="decor" className={cx(styles.ModalContent__image)} />
      )}
      <p className={cx(styles.ModalContent__title)}>{translates.title}</p>
      <p className={cx(styles.ModalContent__text)}>{translates.descr1}</p>
      <p className={cx(styles.ModalContent__text)}>{translates.descr2}</p>
      <Button className={cx(styles.ModalContent__button)} onClick={closeModal}>
        {translates.button}
      </Button>
    </ModalComp>
  );
};

export default ModalComponentCheckpoint;
