import ky1 from './Art.png';
import ky2 from './Art-1.png';
import ky3 from './Art-2.png';
import ky4 from './Art-3.png';
import ky5 from './Art-4.png';
import ky1_p from './Art1_p.png';
import ky2_p from './Art2_p.png';
import ky3_p from './Art3_p.png';
import ky4_p from './Art4_p.png';
import ky5_p from './Art5_p.png';
import ky_decor from './woman.png';

export const kuAssets = {
  ky1,
  ky2,
  ky3,
  ky4,
  ky5,
  ky1_p,
  ky2_p,
  ky3_p,
  ky4_p,
  ky5_p,
  ky_decor,
}
